
export const environment = {
  production: false,
  stripe: {
    publishableKey: 'pk_test_51NQAAEGNXSAYunDVGZDTXze8ZkL4lU46BGnFED7vyBvYf1jCMTvZ2pty9cNFvFgayYg9wkq7zkGGHK8A2GNQsRV200vD8Oc0cc',
    secretKey: 'sk_test_51NQAAEGNXSAYunDVYzneLugDAQIs1hyM1v9C69HRqEBHqtlpC2srFqKbKJyemnORMOxBOYEZNdm4WoXTRTPae9uq00MesICk76',
  },
};
// export const BASE_URL_API = `https://api-layaya.acciosoft.com`
export const BASE_URL_API = 'https://api.layayaheladeria.com';
// export const BASE_URL_API = 'http://localhost:3000'


