import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  lastRoute: string = '/home';
  constructor(
    private authService: AuthService,
    private router: Router,
    private platform: Platform
  ) {}

  async ngOnInit() {
    const isLoggedIn = await this.authService.isAuthenticated();
    this.authService.setLoggedIn(isLoggedIn); // Ajusta el estado interno según el token

    if (isLoggedIn) {
      this.router.navigate(['home']);
    } else {
      this.router.navigate(['sign-in']);
    }

    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.lastRoute && this.lastRoute.includes('/sign-in')) {
        // If the last route was sign-in, navigate to home instead of going back
        this.router.navigate(['/home']);
      } else {
        // Normally, go back in history
        window.history.back();
      }
    });
  }
  }
