import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().then(isAuthenticated => {
      if (isAuthenticated && state.url.includes('/sign-in')) {
        // If the user is authenticated and trying to access the sign-in page, redirect them to the home page.
        this.router.navigate(['/home']);
        return false;
      } else if (!isAuthenticated && !state.url.includes('/sign-in')) {
        // If the user is not authenticated and trying to access a protected route, redirect them to sign-in.
        this.router.navigate(['/sign-in']);
        return false;
      }
      return true;
    });
  }
}
