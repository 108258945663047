import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from '../../environments/environment';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();
  private _storage: Storage | null = null;
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: Storage
  ) {
    this.initStorage().then(() => {
      this.checkInitialAuthentication();
    });
  }

  async initStorage() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async checkInitialAuthentication() {
    const token = await this.getToken();
    const isLoggedIn = token != null;
    this.setLoggedIn(isLoggedIn);

    if (isLoggedIn) {
      this.router.navigate(['home']);
    } else {
      this.router.navigate(['sign-in']);
    }
  }

  login(data: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/login`, data).pipe(
      tap(async response => {
        await this._storage?.set('auth_token', response.token);
        this.isLoggedInSubject.next(true);
      }),
      catchError(this.handleError)
    );
  }

  async logout(): Promise<void> {
    console.log("Intentando eliminar el token...");
    await this._storage?.remove('auth_token');
    this.isLoggedInSubject.next(false);
    console.log("Token eliminado.");
    this.router.navigate(['sign-in']);
  }

  async isAuthenticated() {
    const token = await this._storage?.get('auth_token');
    return token != null;
  }

  setLoggedIn(value: boolean) {
    this.isLoggedInSubject.next(value);
  }

  async getToken() {
    return await this._storage?.get('auth_token');
  }

  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was:`, error.error);
    let errorMessage = error.error;
    if (error.status === 401) {
      errorMessage = error.error || 'Credenciales inválidas. Por favor, verifica tu correo y contraseña.';
    }
    return throwError(errorMessage);
  }
}
