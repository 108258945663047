
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'sign-in',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },

  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'modal-qr',
    loadChildren: () => import('./pages/modal-qr/modal-qr.module').then( m => m.ModalQRPageModule)
  },
  {
    path: 'cupones',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/cupones/cupones.module').then( m => m.CuponesPageModule)
  },
  {
    path: 'ofertas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ofertas/ofertas.module').then( m => m.OfertasPageModule)
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  
  {
    path: 'productos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/productos/productos.module').then( m => m.ProductosPageModule)
  },
  {
    path: 'plantilla-productos',
    loadChildren: () => import('./pages/plantilla-productos/plantilla-productos.module').then( m => m.PlantillaProductosPageModule)
  },
  {
    path: 'plantilla-productos-vertical',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/plantilla-productos-vertical/plantilla-productos-vertical.module').then( m => m.PlantillaProductosVerticalPageModule)
  },
  {
    path: 'producto-completo',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/producto-completo/producto-completo.module').then( m => m.ProductoCompletoPageModule)
  },
  {
    path: 'cupon-modal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/cupon-modal/cupon-modal.module').then( m => m.CuponModalPageModule)
  }





];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
